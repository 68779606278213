<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Dotazníky - Statistiky <span class="text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="max-height-600">
        <canvas ref="myChart" width="600" height="600"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import Chart from 'chart.js'

const colors = [{
  background: 'rgba(255, 99, 132, 0.2)',
  border: 'rgba(255, 99, 132, 1)',
}, {
  background: 'rgba(54, 162, 235, 0.2)',
  border: 'rgba(54, 162, 235, 1)',
}, {
  background: 'rgba(255, 206, 86, 0.2)',
  border: 'rgba(255, 206, 86, 1)',
}, {
  background: 'rgba(75, 192, 192, 0.2)',
  border: 'rgba(75, 192, 192, 1)',
}, {
  background: 'rgba(153, 102, 255, 0.2)',
  border: 'rgba(153, 102, 255, 1)',
}, {
  background: 'rgba(255, 159, 64, 0.2)',
  border: 'rgba(255, 159, 64, 1)',
}, {
  background: 'rgb(0, 0, 0, 0.2)',
  border: 'rgb(0, 0, 0, 1)',
}, {
  background: 'rgba(228,47,198,0.2)',
  border: 'rgba(228,47,198,1)',
}, {
  background: 'rgba(47,65,228,0.2)',
  border: 'rgba(47,65,228,1)',
}, {
  background: 'rgba(228,83,47,0.2)',
  border: 'rgba(228,83,47,1)',
}, {
  background: 'rgba(95,228,47,0.2)',
  border: 'rgba(95,228,47,1)',
}, {
  background: 'rgb(211,0,0, 0.2)',
  border: 'rgb(211,0,0, 1)',
}, {
  background: 'rgb(47, 228, 159, 0.2)',
  border: 'rgb(47, 228, 159, 1)',
}]

export default {
  components: { ActionTools },
  data: function () {
    return {
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/survey',
          title: 'Seznam',
        },
      ],
      id: this.$route.params.id,
      loaded: [],
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
    detail: function () {
      return this.$store.getters['survey/getDetail']
    },
    filteredDetail: function () {
      return this.$store.getters['survey/currentLanguageDetail']
    },
    randomBackgroundColors: function () {
      const number = this.filteredDetail.answers ? this.filteredDetail.answers.length : 0
      return colors.slice(0, number)
    },
  },
  created() {
    if (this.language !== null && !this.loaded.includes(this.language)) {
      this.initDetail()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (newValue !== null) {
        if (!this.loaded.includes(this.language)) {
          this.initDetail()
        } else if (!this.loaded.includes(this.language)) {
          this.initData()
        }
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initDetail()
      }
    },
  },
  methods: {
    initDetail() {
      this.loading = true
      this.$store.dispatch('survey/getOne', this.id)
        .then(() => {
          this.createChart()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.loaded.push(this.language)
        })
    },
    createChart() {
      const ctx = this.$refs.myChart.getContext('2d')
      // eslint-disable-next-line no-unused-vars,no-new
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.filteredDetail.answers ? this.filteredDetail.answers.map(x => x.sae_answer) : [],
          datasets: [{
            label: this.filteredDetail ? this.filteredDetail.sle_question : null,
            data: this.detail.answers ? this.detail.answers.map(x => x.users_count) : [],
            backgroundColor: this.randomBackgroundColors.map(x => x.background),
            borderColor: this.randomBackgroundColors.map(x => x.border),
            borderWidth: 1,
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            }],
          },
        },
      })
    },
    refreshComponent(id) {
      this.id = id
      this.loaded = []
    },
  },
}
</script>
